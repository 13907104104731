<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml">
</i18n>
<i18n locale="th" lang="yaml">
service_repair.repair.man_hours : "ใช้เวลาซ่อมโดยประมาณ"
service_repair.field.repair_detail_rich.placeholder : "เช่น จากการตรวจสอบพบว่าแผงวงจรใช้งานปกติ แต่ตัว Connector หลวม เป็นต้น"
</i18n>

<template>
	<div class="service_repair_detail-container">
		<template v-if="canUpdate">
			<div class="service_repair_detail-row">
				<label>{{$t('service_repair.field.man_hours')}} * : </label>
				<div class="service_repair_detail-input-block">
					<a-input-number
							v-model="repairDetailData.man_hours"
							:min="0" :step="0.50"
							:precision="2"
							class="myinput-number"
							length="20">
					</a-input-number>
					<span class="ant-form-text">
						{{$t('common.hour')}}
					</span>
				</div>
			</div>
			<div class="service_repair_detail-row">
				<label>{{$t('service_repair.field.repair_detail_rich')}} * : </label>
				<RichTextInput ref="richTextRef"
					v-model="repairDetailData.repair_detail_rich"
					:placeholder="$t('service_repair.field.repair_detail_rich.placeholder')"/>
			</div>
		</template>
		<template v-else>
			<div class="service_repair_detail-row">
				<p>
					{{$t('service_repair.repair.man_hours')}}
					<span v-if="repairDetailData.man_hours > 0" class="text-value">
						{{repairDetailData.man_hours}} {{$t('common.hour')}}
					</span>
					<span v-else class="text-value">
						{{$t('common.unspecify')}}
					</span>
				</p>
				<label>{{$t('service_repair.field.repair_detail_rich')}} : </label>
				<QuillViewer :content="repairDetailData.repair_detail_rich"
					:empty-message="$t('common.unspecify')"/>
			</div>
		</template>
	</div>
</template>

<script>
import RichTextInput from "@components/input/RichTextInput.vue"
import QuillViewer from "@components/common/QuillViewer.vue"
import {InputNumber} from "ant-design-vue"
import {replaceDeepProperty,copyDeep} from "@utils/objectUtil"
function _defaultRepairDetailData() {
	return {
		repair_detail_rich : undefined,
		man_hours : undefined,
	}
}
export default {
	components : {
		RichTextInput , QuillViewer,
		"a-input-number" : InputNumber
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean ,
			default : false,
		}
	} ,
	data() {
		return {
			repairDetailData : _defaultRepairDetailData() ,
		}
	} ,
	methods : {
		setData(data) {
			this.repairDetailData = _defaultRepairDetailData()
			if (data) {
				const repairDetail = data.repair_detail_rich
				delete data.repair_detail_rich
				replaceDeepProperty(this.repairDetailData,data)
				if (this.$refs.richTextRef) {
					this.$refs.richTextRef.setContent(repairDetail)
				} else {
					this.repairDetailData.repair_detail_rich = repairDetail
				}
			}
		} ,
		getData() {
			const data = copyDeep(this.repairDetailData)
			return data
		} ,
		clear() {
			this.repairDetailData = _defaultRepairDetailData()
		}
	}
}
</script>

<style lang="less" scoped>
.service_repair_detail-row {
	margin-bottom : 12px;
	label {
		color : @info-color;
		margin-right : 2px;
	}
}

.service_repair_detail-input-block {
	display : inline-block;
}
</style>
