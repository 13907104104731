<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
repair_inspect.is_not_inspect.message : "ไม่ได้ตรวจสอบหลังซ่อม"
</i18n>

<template>
	<div>
		<div v-if="canUpdate" class="repair-not-inspect">
			<a-checkbox
				v-model="inspection.is_not_inspect">
				{{$t('service_repair.field.is_not_inspect')}}
			</a-checkbox>
		</div>
		<BlockPane :block="inspection.is_not_inspect">
			<span slot="message">
				{{$t('repair_inspect.is_not_inspect.message')}}
			</span>
			<component
				:is="inspectComponent"
				ref="inspectionRef"
				:service="service"
				:checklist-type="inspection.checklist_type"
				:can-update="canUpdate && !inspection.is_not_inspect"/>
		</BlockPane>
	</div>
</template>

<script>
import {Checkbox} from "ant-design-vue"
import {transformToJsonProp} from "@utils/objectUtil"
import BlockPane from "@components/common/BlockPane.vue"
import InvalidChecklistType from "@components/service/InvalidChecklistType.vue"
import RepairTGD1XInspection from "@components/service/tgd1x/RepairTGD1XInspection.vue"
export default {
	components : {
		BlockPane,
		"a-checkbox" : Checkbox,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			inspection : {
				is_not_inspect : false,
				checklist_type : undefined,
			}
		}
	} ,
	computed : {
		inspectComponent() {
			switch(this.inspection.checklist_type) {
				case 'tgd1x' :
					return RepairTGD1XInspection
				default :
					return InvalidChecklistType
			}
		}
	} ,
	methods : {
		setData(data) {
			this.inspection.is_not_inspect = data.is_not_inspect
			this.inspection.checklist_type = data.checklist_type
			this.$nextTick(()=>{
				const inspectComp = this.$refs.inspectionRef
				if (inspectComp && 'setData' in inspectComp) {
					if (data.serviceInspectionData) {
						inspectComp.setData(data.serviceInspectionData)
					} else {
						inspectComp.setData({})
					}
				}
			})
		} ,
		getData() {
			const formData = {...this.inspection}
			const inspectComp = this.$refs.inspectionRef
			if (inspectComp && typeof inspectComp.getData === 'function') {
				formData.serviceInspectionData = inspectComp.getData()
				transformToJsonProp(formData,'serviceInspectionData','service_inspection_json')
			} else {
				formData.service_inspection_json = null
			}
			return formData
		}
	}
}
</script>

<style lang="less" scoped>
.repair-not-inspect {
	margin-bottom : 12px;
}
</style>
