<i18n locale="th" lang="yaml" >
service_suggest.firmware : "แนะนำให้ Update Firmware เป็น Version ล่าสุด ({firmware})"
</i18n>

<template>
	<div v-show="showDisplay" class="service-suggestion-container">
		<div v-if="showFirmware" class="service-suggestion-firmware">
			{{firmwareMessage}}
		</div>
		<div v-if="showMaintenance" class="service-suggestion-firmware">
			{{maintenanceMessage}}
		</div>
	</div>
</template>

<script>
import _get from "lodash/get"
import {mapGetters} from "vuex"
export default {
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		currentFirmwareId : {
			type : Number,
			default : undefined,
		} ,
		canUpdate : {
			type : Boolean ,
			default : false,
		} ,
		hasFirmwareAlready : {
			type : Boolean,
			default : false,
		} ,
		hasMaintenanceAlready : {
			type : Boolean,
			default : false,
		}
	} ,
	computed : {
		...mapGetters('drone',['getLatestFirmwareBySet']) ,
		needSuggestion() {
			return this.canUpdate && !this.service.is_device_only
		} ,

		firmwareMessage() {
			if (this.needSuggestion) {
				const currentFirmwareId = this.currentFirmwareId ? this.currentFirmwareId : _get(this.service,"droneData.firmware.id")
				const firmwareSetId = _get(this.service,"droneData.model.firmware_set_id")
				const latestFirmware = this.getLatestFirmwareBySet(firmwareSetId)
				if (latestFirmware !== false && latestFirmware.id != currentFirmwareId) {
					// Need Upgrade
					return this.$t('service_suggest.firmware',{firmware : latestFirmware.name})
				}
			}
			return null
		} ,
		maintenanceMessage() {
			if (this.needSuggestion) {
				// TODO
			}
			return null
		} ,
		showFirmware() {
			return !this.hasFirmwareAlready && this.$notEmpty(this.firmwareMessage)
		} ,
		showMaintenance() {
			return !this.hasMaintenanceAlready && this.$notEmpty(this.maintenanceMessage)
		} ,
		showDisplay() {
			return this.needSuggestion && (this.showFirmware || this.showMaintenance)
		} ,
	} ,
}
</script>

<style lang="less" scoped>
.service-suggestion-container {
	border : 1px solid @yellow-8;
	border-radius : @border-radius-base;
	background-color : @yellow-1;
	max-width : 800px;
	margin : 0 auto 24px;
	padding : 16px 36px;
	font-size : 1.1em;
	font-family: @font-family-title;

	.mobile & {
		font-size : 1em;
		margin-top : 12px;
	}
}
</style>
