import { render, staticRenderFns } from "./ServiceJob.vue?vue&type=template&id=5d4544d1&scoped=true"
import script from "./ServiceJob.vue?vue&type=script&lang=js"
export * from "./ServiceJob.vue?vue&type=script&lang=js"
import style0 from "./ServiceJob.vue?vue&type=style&index=0&id=5d4544d1&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4544d1",
  null
  
)

/* custom blocks */
import block0 from "@i18n/service/service-job.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fservice%2FServiceJob.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports