<i18n locale="th" lang="yaml" >
service_firmware.description : "ทำการ Update Firmware ให้กับ Drone ตัวนี้ โดยส่วนใหญ่ควรจะ Update Firmware ให้เป็น Version ล่าสุดเสมอ"
service_firmware.field.from_firmware : "Firmware ของ Drone"
service_firmware.field.to_firmware : "Firmware ใหม่"
service_firmware.field.detail_rich : "ข้อมูลเพิ่มเติม"
service_firmware.field.detail_rich.placeholder : "เช่น ถ้าไม่ได้ Update เป็น Version ล่าสุดเพราะอะไร เป็นต้น"
service_firmware.use_latest_data : "ตั้งให้เป็นข้อมูลปัจจุบัน"
service_firmware.view.from : "Update Firmware จาก Version "
service_firmware.view.to : "เป็น Version"
</i18n>

<template>
	<div class="firmware-container">
		<div class="firmware-description">
			{{$t('service_firmware.description')}}
		</div>

		<ErrorMessagePane :error="error" :only-model-keys="['job_firmware']"/>

		<template v-if="canUpdate">
			<div class="firmware-block" >
				<a-button :disabled='isLatestData' @click="handleLatestData">
					{{$t('service_firmware.use_latest_data')}}
				</a-button>
			</div>
			<div class="firmware-block">
				<label>{{$t('service_firmware.field.from_firmware')}}</label>
				<FirmwareSelect v-model="serviceFirmware.from_firmware_id"
					:firmware-set-id="firmwareSetId"
					allow-clear
					@change="handleFromFirmware"/>
			</div>
			<div class="firmware-block">
				<label>{{$t('service_firmware.field.to_firmware')}}</label>
				<FirmwareSelect v-model="serviceFirmware.to_firmware_id"
					:firmware-set-id="firmwareSetId"
					:disabled-firmwares="serviceFirmware.from_firmware_id"
					allow-clear/>
			</div>
		</template>
		<div v-else class="firmware-block view">
			<span class="detail">{{$t('service_firmware.view.from')}}</span>
			<span class="text-value">{{fromFirmware | emptyCheck}}</span>
			<span class="detail">{{$t('service_firmware.view.to')}}</span>
			<span class="text-value">{{toFirmware | emptyCheck}}</span>
		</div>

		<div v-if="canUpdate || $notEmpty(serviceFirmware.detail_rich)" class="firmware-block firmware-row">
			<label>{{$t('service_firmware.field.detail_rich')}}</label>
			<RichTextInput
				v-if="canUpdate"
				v-model="serviceFirmware.detail_rich"
				:placeholder="$t('service_firmware.field.detail_rich.placeholder')"
				class="firmware-richinput"/>
			<QuillViewer v-else :content="serviceFirmware.detail_rich" />
		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex"
import {replaceDeepProperty,copyDeep} from "@utils/objectUtil"
import FirmwareSelect from "@components/firmware/FirmwareSelect.vue"
import RichTextInput from "@components/input/RichTextInput.vue"
import QuillViewer from "@components/common/QuillViewer.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import _get from "lodash/get"

function _defaultFirmwareData() {
	return {
		from_firmware_id : undefined,
		to_firmware_id : undefined,
		detail_rich : undefined
	}
}
export default {
	components :{
		FirmwareSelect , RichTextInput, QuillViewer,
		ErrorMessagePane ,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		currentFirmwareId : {
			type : Number,
			default : undefined,
		} ,
		canUpdate : {
			type : Boolean ,
			default : false,
		} ,
		error : {
			type : null,
			default : undefined,
		}
	} ,
	data() {
		return {
			serviceFirmware : _defaultFirmwareData()
		}
	} ,
	computed : {
		...mapGetters('drone',['getFirmwareById','getLatestFirmwareBySet']) ,
		firmwareSetId() {
			return _get(this.service,"droneData.model.firmware_set_id")
		} ,
		isLatestData() {
			const latestFirmware = this.getLatestFirmwareBySet(this.firmwareSetId)
			return (
					(this.serviceFirmware.from_firmware_id == _get(this.service,"droneData.firmware.id"))
					&&
					(this.serviceFirmware.to_firmware_id == latestFirmware.id))
		} ,
		fromFirmware() {
			return this.getFirmwareById(this.serviceFirmware.from_firmware_id).name
		} ,
		toFirmware() {
			return this.getFirmwareById(this.serviceFirmware.to_firmware_id).name
		}
	} ,
	methods : {
		handleLatestData() {
			this.serviceFirmware.from_firmware_id = this.currentFirmwareId ? this.currentFirmwareId : _get(this.service,"droneData.firmware.id")
			const latestFirmware = this.getLatestFirmwareBySet(this.firmwareSetId)
			if (latestFirmware !== false)
				this.serviceFirmware.to_firmware_id = latestFirmware.id
		} ,
		handleFromFirmware() {
			if (this.serviceFirmware.from_firmware_id == this.serviceFirmware.to_firmware_id) {
				this.serviceFirmware.to_firmware_id = undefined
			}
		} ,
		setData(data) {
			this.serviceFirmware = _defaultFirmwareData()
			if (data.id && data.id > 0)  {
				replaceDeepProperty(this.serviceFirmware,data)
			} else {
				this.handleLatestData()
			}
		} ,
		getData() {
			return copyDeep(this.serviceFirmware)
		}
	}
}
</script>

<style lang="less" scoped>
.firmware-description {
	color : @text-muted;
	margin-bottom : 24px;
}
.firmware-block {
	margin-bottom : 12px;
	label {
		display : inline-block;
		color : @info-color;
		width : 150px;
		&::after {
			content : ' :'
		}
	}
}
.firmware-richinput {
	background-color : @white;
	margin-top : 4px;
}
.firmware-block.view {
	font-size : 1.1em;
	border : 1px dashed @border-color-base;
	padding : 12px 24px;
	max-width : 800px;
	background : @white;
	.text-value {
		color : @info-color;
		text-decoration: none;
	}
}
</style>
